import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
//import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//https://github.com/duskload/react-device-detect/blob/HEAD/docs/views.md
import { browserName, browserVersion } from "react-device-detect";
import { BrowserView, TabletView, MobileView, AndroidView, IOSView, isBrowser, isMobile } from 'react-device-detect';


import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Placeholder from 'react-bootstrap/Placeholder';

import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';


import { Sanctum, useSanctum } from "react-sanctum";


const axios = require('axios').default;

function AppMenu(props) {
    const [result, setResult] = useState([]);
    const [itemsArray, setItemsArray] = useState([]);

    useEffect(() => {
        //checkAR();
        //setARInteract(id);

        //getData();
        console.log('itemsArray', itemsArray);


    }, [])

    ///
    function getData() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: props.data.apiUrl + props.data.restaurantId + '/categories',
            //responseType: 'stream'
            responseType: 'json'
        })
            .then(function (response) {
                console.log(response);
                setItemsArray(response.data[0]);

                //ResponseStatus
                console.log(response.data[1]);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // always executed

            });
    }
    //const { authenticated, user, signIn } = useSanctum();

    return (

        <Navbar expand="lg" sticky="top" variant="dark" className='backgroundColor shadow2 text-light'>
            <Navbar.Brand href="/">
                <span className='cursiveSm text-light m-2'>Maincourse.io | Restaurant Name</span>
              
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end mr-2">
                <Nav className="container-fluid" >
                <Nav.Item className="mr-auto ">
                        <Nav.Link as={Link} to='/dashboard'  >
                            <span className='text-light m-2'>
                                Dashboard
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="mr-auto ">
                        <Nav.Link as={Link} to='/1/menu'  >
                            <span className='text-light m-2'>
                                Menu(Server)
                            </span>
                        </Nav.Link>
                    </Nav.Item>
                    {itemsArray.map((data, key) => {
                        return (
                            <Nav.Item key={key} className="mr-auto">
                                <Nav.Link as={Link} to={`/` + data.restaurant_id + `/menu/` + data.slug} >
                                    <span className='text-light m-2' title={data.description}>
                                        {data.name}
                                    </span>
                                </Nav.Link>
                            </Nav.Item>

                        )
                    })}
                    <NavDropdown title='Staff' id="nav-dropdown-dark-example" align="end" menuVariant="dark"  >

                        <NavDropdown.Item as={Link} to='/1/manager' > Managers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/1/server' > Servers</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/1/bartender' > Bartenders</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/1/cooks' > Cooks</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/1/reception' > Reception</NavDropdown.Item>
                        <NavDropdown.Divider></NavDropdown.Divider>
                        <NavDropdown.Item as={Link} to='/1/valet' > Valet</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/1/promoter' > Promoter</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/1/courier' > Courier</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title='Active Lists' id="basic-nav-dropdown" align="end" >
                        <NavDropdown.Item as={Link} to='/1/tables' > Tables</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/1/staff' > Staff</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/reservations' > Reservations</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/checkins' > Check In's</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/1/orders' > Orders</NavDropdown.Item>
                        <NavDropdown.Divider></NavDropdown.Divider>
                        <NavDropdown.Item as={Link} to='/bills' > Bills</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/payments' > Payments</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title='Settings' id="basic-nav-dropdown" align="end" >
                        <NavDropdown.Item as={Link} to='/RestaurantManager' > Restaurant Manager</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/StaffManager' > Staff Manager</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/TableManager' > Table Manager</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/MenuManager' > Menu Manager</NavDropdown.Item>
                        <NavDropdown.Divider></NavDropdown.Divider>
                        <NavDropdown.Item as={Link} to='/StyleManager' > Style Manager</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown title='OtherTodo' id="basic-nav-dropdown" align="end" >
                        <NavDropdown.Item as={Link} to='/scan' > QR Scanner</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/about' > Hours</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/about' > About</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/' > AR Brand Experience</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/' > Kids Menu</NavDropdown.Item>
                        <NavDropdown.Divider></NavDropdown.Divider>
                        <NavDropdown.Item as={Link} to='/ingredients' > Ingredients</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/supply' > Supply</NavDropdown.Item>
                        <NavDropdown.Divider></NavDropdown.Divider>
                        <NavDropdown.Item as={Link} to='/ingredients' > Payments</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/supply' > Tips</NavDropdown.Item>
                        <NavDropdown.Divider></NavDropdown.Divider>
                        <NavDropdown.Item as={Link} to='/ingredients' > Order</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/ingredients' > Payments</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to='/supply' > Tips</NavDropdown.Item>

                    </NavDropdown>

                </Nav>
                <Nav>


                    <NavDropdown title='Staff User' id="basic-nav-dropdown" align="end" >
                        {/*<LoginButton></LoginButton>
                        <LogoutButton></LogoutButton>*/}
                        <NavDropdown.Item as={Link} to="/login">Login</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/register">Register</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/logout">Logout</NavDropdown.Item>
                        <NavDropdown.Divider></NavDropdown.Divider>
                        <NavDropdown.Item as={Link} to="/me">me</NavDropdown.Item>

                        <NavDropdown.Item as={Link} to="/logout">My Orders</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/logout">My Reservations</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/logout">Notifications</NavDropdown.Item>
                        <NavDropdown.Divider></NavDropdown.Divider>
                        <NavDropdown.Item as={Link} to="/logout">ClockIn</NavDropdown.Item>
                        <NavDropdown.Item as={Link} to="/logout">ClockOut</NavDropdown.Item>


                    </NavDropdown>

                </Nav>


            </Navbar.Collapse>

        </Navbar>


    )
}
export default AppMenu;