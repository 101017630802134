import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import AppMenu from '../AppMenu';
import Swal from 'sweetalert2'


const axios = require('axios').default;

//https://www.youtube.com/watch?v=cQLrtwxaWNo&list=PLRheCL1cXHrtT6rOSlab8VzMKBlfL-IEA&t=0s
//https://laravel.com/docs/8.x/sanctum
//https://github.com/koole/react-sanctum

function Logout(props) {

    const history = useHistory();



    const loginSubmit = (e) => {
        e.preventDefault();



        axios.get(props.data.apiUrl + `logout`).then(res => {
            console.log(res);
            if (res.status === 200) {
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_name');
                Swal.fire("Success", res.data.message, "success");
                history.push('/');
            } else if (res.status === 401) {

            } else {
                //setLogin({ ...loginInput, error_list: res.data.validation_errors })
            }
        })

    }
    return (
        <div>
            <AppMenu data={props.data} />
            <h1>Logout</h1>
            <form onSubmit={loginSubmit}>

                <button type='submit'>Logout</button>
            </form>
        </div>
    )
}

export default Logout
