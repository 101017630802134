import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//https://github.com/duskload/react-device-detect/blob/HEAD/docs/views.md
import { browserName, browserVersion } from "react-device-detect";
import { BrowserView, TabletView, MobileView, AndroidView, IOSView, isBrowser, isMobile } from 'react-device-detect';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Placeholder from 'react-bootstrap/Placeholder';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';


import QrReader from 'react-qr-reader'

import AppMenu from '../AppMenu';

const axios = require('axios').default;

function Orders(props) {
    const { restaurant_id } = useParams();

    const [show, setShow] = useState(false);
    const [alertShow, setAlertShow] = useState(true);
    const [placeShow, setPlaceShow] = useState(false);

    const [id, setID] = useState('');
    //const [ar, setAR] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [itemsArray, setItemsArray] = useState([]);
    const [detailArray, setDetailArray] = useState([]);

    const setDetail = (id) => {
        setDetailArray(itemsArray[id]);
        console.log('detailArray', detailArray);
    };

    useEffect(() => {
        //checkAR();
        //setARInteract(id);

        getData();
        console.log('itemsArray', itemsArray);


    }, [])

    useEffect(() => {

    }, [])

    ///
    function getData() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: props.data.apiUrl + restaurant_id + '/orders',
            //responseType: 'stream'
            responseType: 'json'
        })
            .then(function (response) {
                console.log(response);
                setItemsArray(response.data[0]);

                //ResponseStatus
                console.log(response.data[1]);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // always executed

            });
    }

    return (
        <div style={{ backgroundColor: '#333', minHeight:'100vh',backgroundImage: `url("/MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>
            <AppMenu data={props.data} />
            <div className='p-0 m-0'>
                <h1 className='pt-4 p- cursive backgroundColor text-light' style={{ marginBottom: 0 }}>Orders</h1>
            </div>
            <ListGroup as="ol" className="m-0">
                {itemsArray.map((data, key) => {
                    return (
                        <ListGroup.Item
                            key={key}
                            as="li"
                            style={{ backgroundColor: '#ffffff99' }}
                            className="p-0 d-flex justify-content-between align-items-start"
                            onClick={() => { handleShow(); setID(data.id); setDetail(key); }}
                        >
                            <img src={data.avatar} width='125px' />
                            <div className="ms-2 me-auto m-auto" >
                                <div className="p-0 d-flex justify-content-between align-items-start">
                                <div className="fw-bold">Order: {data.id}</div>
                                    <div className="fw-bold">Table: {data.table_id}</div>

                                </div>
                                <div>
                                Server: {data.server_id}
                                Cook: {data.cook_id}
                                ${data.amount}
                                    {data.notes}
                                </div>
                            </div>
                            <div className="p-0 d-flex justify-content-between align-items-middle">
                                Cat: {data.category}
                                Items: {data.items}



                            </div>
                            <div className="p-0 d-flex justify-content-between align-items-middle">
                                <Badge variant="primary" className='m-4' pill>
                                    Status: {data.status}
                                </Badge>
                            </div>

                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <Row className="show-grid" >


                {itemsArray.map((data, key) => {
                    return (
                        <Col key={key} sm={6} md={3} >
                            <Card onClick={() => { handleShow(); setID(data.id); }}>
                                <Card.Img src={data.avatar} alt="Card image" />
                                <Card.ImgOverlay >
                                    <Card.Body to={`/` + data.restaurant_id + `/menu/` + data.slug} >
                                        <span className='text-light m-2 cursive3' title={data.description}>
                                            {data.name}

                                        </span>
                                        <p className='text-light cursiveSm'>{data.description} </p>


                                    </Card.Body>
                                </Card.ImgOverlay>
                            </Card>
                        </Col>

                    )
                })}
            </Row>

            <Offcanvas
                show={show}
                placement="end"
                scroll='false'
                backdrop='true'
                onHide={handleClose}>

                <Offcanvas.Header closeButton>

                    <Offcanvas.Title>
                        name
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>description</p>

                    {/*CAMERA SOCIAL SHARE STYLE CAnonical */}
                    <a id='ar-link' href="mask.usdz#canonicalWebPageURL=https://www.vizcache.com" rel="ar">
                        <img src='salad.jpg' width='100%' />
                    </a>

                    <hr />


                    Map
                    Hours
                    About
                    Entertainement
                    Reservations
                    <hr />
                    <Button>Add to Order</Button>
                    <hr />
                    Loading UI | Some text as placeholder.In real life you can have the elements you
                    have chosen.Like, text, images, lists, etc.
                    < Placeholder visibility={placeShow} as={Offcanvas.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder visibility={placeShow} as={Offcanvas.Text} animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                        <Placeholder xs={6} /> <Placeholder xs={8} />
                    </Placeholder>
                    <hr />

                </Offcanvas.Body>
                <BrowserView>
                    <Alert variant='secondary' className='m-0' onClose={() => setAlertShow(false)} dismissible>
                        <img src='ar.png' width='45px' />

                        Offcanvas Detail for ID:{id}
                        <h6>This is rendered only in browser</h6>
                        <hr />

                    </Alert>

                </BrowserView>
                <AndroidView>
                    <Alert variant='secondary' className='m-0'>
                        <h6>This is rendered only on Android</h6>
                    </Alert>
                </AndroidView>
                <IOSView>
                    <Alert variant='secondary' className='m-0'>
                        <h6>This is rendered only on IOS</h6>
                    </Alert>
                </IOSView>
            </Offcanvas>

        </div>
    )
}

export default Orders;

/*

                    */