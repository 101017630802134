import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import AppMenu from '../AppMenu';
import Swal from 'sweetalert2'

import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;
/*
axios.interceptors.request.use(

  function (config){
    const token = localStorage.getItem('auth_token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;

  }
);
*/


//const axios = require('axios').default;

//https://www.youtube.com/watch?v=cQLrtwxaWNo&list=PLRheCL1cXHrtT6rOSlab8VzMKBlfL-IEA&t=0s
//https://laravel.com/docs/8.x/sanctum
//https://github.com/koole/react-sanctum

function Me(props) {
    //GET
    const [itemsArray, setItemsArray] = useState([]);

    //const sanctum = useSanctum();
    ///
    useEffect(() => {

        getData();

    }, [])

    function getData() {
        // Optionally the request above could also be done as
        //axios.defaults.withCredentials = true;
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers.post['Accept'] = 'application/json';
        //axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

        axios.defaults.withCredentials = true;
        axios.get('https://www.api.maincourse.io/sanctum/csrf-cookie').then(response => {
            axios({
                method: 'get',
                withCredentials: true,
                url: props.data.apiUrl + 'user',
                /*
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('auth_token')}`
                },
                */

                //responseType: 'stream'
                responseType: 'json'
            })
                .then(function (response) {
                    console.log(response);
                    setItemsArray(response.data[0]);
                    //ResponseStatus
                    console.log(response.data[1]);
                })
                .catch(function (error) {
                    console.log(error);
                })
                .then(function () {
                    // always executed
                });
        });

    }



    return (
        <div>
            <AppMenu data={props.data} />
            <h1>Me</h1>
            {localStorage.getItem('auth_token')}
            <hr />
            {localStorage.getItem('auth_name')}

        </div>
    )
}

export default Me
