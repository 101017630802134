import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//https://github.com/duskload/react-device-detect/blob/HEAD/docs/views.md
import { browserName, browserVersion } from "react-device-detect";
import { BrowserView, TabletView, MobileView, AndroidView, IOSView, isBrowser, isMobile } from 'react-device-detect';

import Alert from 'react-bootstrap/Alert';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Placeholder from 'react-bootstrap/Placeholder';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';

import QrReader from 'react-qr-reader'

const axios = require('axios').default;

function Menus(props) {
    const [show, setShow] = useState(false);
    const [alertShow, setAlertShow] = useState(true);
    const [placeShow, setPlaceShow] = useState(false);

    const [menusArray, setMenusArray] = useState([]);

    const [id, setID] = useState('');
    //const [ar, setAR] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        //checkAR();
        //setARInteract(id);

        getData();
        
        
        


    }, [])

    ///
    function getData() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: props.data.apiUrl + props.data.restaurantId + '/menus',
            //responseType: 'stream'
            responseType: 'json'
        })
            .then(function (response) {
                console.log(response);
                setMenusArray(response.data[0]);
                console.log('menusArray', menusArray);
             
                //GET String Coded ID to JS Arrays
                let categories = JSON.parse("[" + menusArray['0']['categories']+ "]");
                console.log('categories', categories);
                let items = JSON.parse("[" + menusArray['0']['items']+ "]");
                console.log('items', items);
                let period = JSON.parse("[" + menusArray['0']['period']+ "]");
                console.log('period', period);
                let weekdays = JSON.parse("[" + menusArray['0']['days']+ "]");
                console.log('weekdays', weekdays);

                //ResponseStatus
                console.log(response.data[1].json());
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // always executed

            });
    }

    

    return (
        <div style={{ backgroundColor: '#333' }}>
            <header className="App-header backgroundColor" style={{ backgroundImage: `url("/MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>
                <div class="scooped-corners demo-element backgroundColor mt-5 mb-5" id="demo">
                    <div class="container">
                        <h1 className='cursive3'>
                            Restaturant Name
                        </h1>
                        <img src='/steak.jpg' className="App-logo" alt="logo" />

                        <h3 className='cursivesm m-4' >Premium Augmented Menus</h3>
                        <p className='cursiveSm' >An elegant and simple fine dining experience.</p>
                        {menusArray.map((data, key) => {
                            return (
                                <Card key={key} className="mr-auto">
                                    <Card.Img variant="top" src={data.thumbnailLink} />
                                    <Card.Body as={Link} to={`/` + data.restaurant_id + `/menu/` + data.slug} >
                                        <span className='text-dark m-2' title={data.description}>
                                            {data.name}
                                            {data.items}
                                            {data.categories}
                                        </span>
                                    </Card.Body>
                                </Card>

                            )
                        })}
                        <Row className="justify-content-center">

                            <Col lg='2'>
                                <Card>
                                    <Card.Img variant="top" src="/salad.jpg" />

                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Link className='btn btn-primary shadow' to="/1/menu">
                                            Single
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg='2'>


                                <Card>
                                    <Card.Img variant="top" src="/salad.jpg" />

                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Link className='btn btn-primary shadow' to="/1/menu">
                                            Breakfast
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg='2'>
                                <Card>
                                    <Card.Img variant="top" src="/salad.jpg" />

                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Link className='btn btn-primary shadow' to="/1/menu">
                                            Lunch
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg='2'>
                                <Card>
                                    <Card.Img variant="top" src="/salad.jpg" />

                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Link className='btn btn-primary shadow' to="/1/menu">
                                            Dinner
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg='2'>
                                <Card>
                                    <Card.Img variant="top" src="/salad.jpg" />

                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Link className='btn btn-primary shadow' to="/1/menu">
                                            Bar
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg='2'>
                                <Card>
                                    <Card.Img variant="top" src="/salad.jpg" />

                                    <Card.Body>
                                        <Card.Title>Card Title</Card.Title>
                                        <Link className='btn btn-primary shadow' to="/1/menu">
                                            Other
                                        </Link>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>





                        <p >
                            EN / DE / ES / FR / IT
                        </p>

                    </div>
                </div>
            </header>


        </div>
    )
}

export default Menus;