import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useHistory,
    useParams
} from "react-router-dom";
import AppMenu from '../AppMenu';
import Swal from 'sweetalert2'


const axios = require('axios').default;

//https://www.youtube.com/watch?v=cQLrtwxaWNo&list=PLRheCL1cXHrtT6rOSlab8VzMKBlfL-IEA&t=0s
//https://laravel.com/docs/8.x/sanctum
//https://github.com/koole/react-sanctum
//https://dev.to/dog_smile_factory/authenticating-a-react-app-with-laravel-sanctum-part-3-5db8

function Login(props) {
    const [loginInput, setLogin] = useState({
        email: '',
        password: '',
    });

    const history = useHistory();

    const handleInput = (e) => {
        e.persist();
        setLogin({ ...loginInput, [e.target.name]: e.target.value });
    }

    const loginSubmit = (e) => {
        e.preventDefault();

        const data = {
            email: loginInput.email,
            password: loginInput.password,
            error_list: [],
        }
        axios.defaults.withCredentials = true;
        axios.get('https://www.api.maincourse.io/sanctum/csrf-cookie').then(response => {
            //axios.defaults.headers.post['X-CSRF-Token'] = response.data._csrf;
            console.log(response);
            axios.post(props.data.apiUrl + `login`, data).then(res => {
                axios.get(props.data.apiUrl  + `user`).then(
                    (response) => {
                        console.log('user',response);
                        //setUserId(response.data.id)
                        //setUserName(response.data.name)
                        //setErrorMessage("")
                        //setAuthStatus(LOGGED_IN)
                    },
                    // GET USER ERROR
                    (error) => {
                        console.log('"Could not complete the login"')
                        //setErrorMessage("Could not complete the login")
                    }
                )
                console.log(res);
                if (res.status === 200) {
                    localStorage.setItem('auth_token', res.data.access_token);
                    localStorage.setItem('auth_name', res.data.username);
                    Swal.fire("Success", res.data.message, "success");
                    history.push('/');
                } else if (res.status === 401) {

                } else {
                    setLogin({ ...loginInput, error_list: res.data.validation_errors })
                }
            })
        });
    }
    return (
        <div>
            <AppMenu data={props.data} />
            <h1>Login</h1>
            <form onSubmit={loginSubmit}>
                <div>
                    <label>Email Id</label>
                    <input type='email' name='email' onChange={handleInput} value={loginInput.email}></input>
                    <p>{loginInput.email}</p>
                </div>
                <div>
                    <label>Password</label>
                    <input type='password' name='password' onChange={handleInput} value={loginInput.password}></input>
                    <p>{loginInput.password}</p>
                </div>
                <button type='submit'>Login</button>
            </form>
        </div>
    )
}

export default Login
