import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
//import logo from './logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';



//import Menu from './components/Menu';
//import Item from './components/Item';
import AppMenu from '../AppMenu';


const axios = require('axios').default;

function MenuManager(props) {
    const [id, setID] = useState('');
    const [show, setShow] = useState(false);


    //GET
    const [itemsArray, setItemsArray] = useState([]);
    //POST
    const [itemName, setItemName] = useState('');
    const [itemPrice, setItemPrice] = useState('');
    const [itemThumb, setItemThumb] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    ///
    useEffect(() => {

        getData();

    }, [])

    function getData() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: props.data.apiUrl + props.data.restaurantId + '/items',
            //responseType: 'stream'
            responseType: 'json'
        })
            .then(function (response) {
                console.log(response);
                setItemsArray(response.data[0]);
                //ResponseStatus
                console.log(response.data[1]);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    function addItem(e) {
        e.preventDefault();
        // Send a POST request
        var bodyFormData = new FormData();
        bodyFormData.append('_method', 'post');
        bodyFormData.append('_csrf', '');
        bodyFormData.append('restaurant_id', props.data.restaurantId);
        bodyFormData.append('category', 'Vegan');
        bodyFormData.append('sub_category', 'Burgers');

        //
        //bodyFormData.append('name', itemName);
        bodyFormData.append('name', 'A Cool Name');
        bodyFormData.append('description', 'A Good Description.');
        //bodyFormData.append('price', itemPrice);
        bodyFormData.append('price', '15.99');
        bodyFormData.append('priceUnit', 'piece');
        bodyFormData.append('allergies', '[1,2,3]');
        bodyFormData.append('ageLimit', '0');
        bodyFormData.append('tags', '[1,2,3]');
        bodyFormData.append('list', '1');
        bodyFormData.append('status', 'availiable');
        //
        bodyFormData.append('thumbnailLink', '/image.png');
        //bodyFormData.append('image', imageFile);
        bodyFormData.append('videoLink', '/image.mp4');
        bodyFormData.append('isAr', 1);
        bodyFormData.append('arLink', '/image.usdz');


        axios({
            method: "post",
            url: props.data.apiUrl + props.data.restaurantId + '/items',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            })
            .then(function () {
                // always executed
            });
    };



    return (

        <div>
            <AppMenu data={props.data} />
            <div className='p-0 m-0'>
                <h1 className='pt-4 p- cursive backgroundColor text-light' style={{ marginBottom: 0 }}>Menu Manager</h1>
            </div>
            Change Avalibility
            --
            <Container>
                <Form onSubmit={addItem}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name='name' placeholder="Enter Item Name" />
                        <Form.Text className="text-muted">
                            Item Name
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Price</Form.Label>
                        <Form.Control type="number" name='price' min="0" max="10000" step='.01' placeholder="Enter Price" />
                        <Form.Text className="text-muted">
                            Item Price
                        </Form.Text>
                    </Form.Group>
                    <Button variant="primary" type="submit" onClick={addItem}>
                        Add Item
                    </Button>
                </Form>
            </Container>
            Add Tables
            Add Servers
            <ListGroup as="ol" className="m-0">
                {itemsArray.map((data, key) => {
                    return (
                        <ListGroup.Item
                            key={key}
                            as="li"
                            style={{ backgroundColor: '#ffffff99' }}
                            className="p-0 d-flex justify-content-between align-items-start"
                            onClick={() => { handleShow(); setID(data.id); }}
                        >
                            <img src={data.thumbnailLink} width='125px' />
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{data.name}</div>
                                {data.description}
                            </div>
                            <img src='ar.png' width='50px' />

                            <Badge variant="primary" className='m-4' pill>
                                $ {data.price}
                            </Badge>

                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            <Offcanvas
                show={show}
                placement="end"
                scroll='false'
                backdrop='true'
                onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>


                        ID:{id}

                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <img src='ar.png' width='100px' />
                    ItemEditForm





                    <Button>Save</Button>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default MenuManager;
