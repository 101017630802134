import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
//import logo from './logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Card from 'react-bootstrap/Card';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


//import Menu from './components/Menu';
//import Item from './components/Item';
import AppMenu from '../AppMenu';


const axios = require('axios').default;

function Manager(props) {
    const [id, setID] = useState('');
    const [show, setShow] = useState(false);


    //GET
    const [itemsArray, setItemsArray] = useState([]);
    //POST


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    ////
    function getUser() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: 'http://bit.ly/2mTM3nY',
            responseType: 'stream'
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    function postUser() {
        // Send a POST request
        axios({
            method: 'post',
            url: '/user/12345',
            data: {
                firstName: 'Fred',
                lastName: 'Flintstone'
            }
        }).then(function (response) {
            console.log(response);
        })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    };

    return (

        <div style={{ minHeight:'100vh',backgroundImage: `url("/MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>
            <AppMenu data={props.data} />
            <div className='p-0 m-0'>
                <h1 className='pt-4 p- cursive backgroundColor text-light' style={{ marginBottom: 0 }}>Manager</h1>
            </div>
            <Container>
                <h2 className='text-light' onClick={() => { handleShow(); setID(1); }}>Manager Dashboard</h2>


                <Row className="show-grid" >

                    <Col sm={6} md={4} >
                        <Card className="mr-auto p-5 mb-5" >
                            <Card.Body as={Link} to={`/restaurant_manager`} >
                                <Card.Title>Restarant Manager</Card.Title>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} >
                        <Card className="mr-auto p-5 mb-5">
                            <Card.Body as={Link} to={`/staff_manager`} >
                                <Card.Title>Staff Manager</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} >
                        <Card className="mr-auto p-5 mb-5">
                            <Card.Body as={Link} to={`/table_manager`} >
                                <Card.Title>Table Manager</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} >
                        <Card className="mr-auto p-5 mb-5">
                            <Card.Body as={Link} to={`/menu_manager`} >
                                <Card.Title>Menu Manager</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} >
                        <Card className="mr-auto p-5 mb-5">
                            <Card.Body as={Link} to={`/style_manager`} >
                                <Card.Title>Style Manager</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>

            <Offcanvas
                show={show}
                placement="end"
                scroll='false'
                backdrop='true'
                onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>


                        ID:{id}

                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Form>
                        ORDER ID:
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Server</Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option>Please Select a Server Name</option>
                                <option value="1">Jane</option>
                                <option value="2">John</option>
                                <option value="3">Rasputin</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Table</Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option>Please Select a table Id</option>
                                <option value="1">Table 1</option>
                                <option value="2">Table 2</option>
                                <option value="3">Table 3</option>
                            </Form.Select>
                            <Form.Text className="text-muted">
                                Interior, Patio, Bar...
                            </Form.Text>
                        </Form.Group>

                        Item X Quantity X Chef Notes

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" />
                            <Form.Text className="text-muted">
                                We'll never share your email with anyone else.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                            <Form.Check type="checkbox" label="Check me out" />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Confirm Order
                        </Button>
                    </Form>





                    <Button>Save</Button>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default Manager;
