import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//https://github.com/duskload/react-device-detect/blob/HEAD/docs/views.md
import { browserName, browserVersion } from "react-device-detect";
import { BrowserView, TabletView, MobileView, AndroidView, IOSView, isBrowser, isMobile } from 'react-device-detect';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Placeholder from 'react-bootstrap/Placeholder';

import QrReader from 'react-qr-reader'

const axios = require('axios').default;

function Restaurant(props) {
    const { restaurant_id } = useParams();

    const [show, setShow] = useState(false);
    const [alertShow, setAlertShow] = useState(true);
    const [placeShow, setPlaceShow] = useState(false);

    const [id, setID] = useState('');
    //const [ar, setAR] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [itemsArray, setItemsArray] = useState([]);

    useEffect(() => {
        //checkAR();
        //setARInteract(id);

        getData();
        console.log('itemsArray', itemsArray);


    }, [])

    useEffect(() => {

    }, [])

    ///
    function getData() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: props.data.apiUrl + 'restaurants/' + restaurant_id,
            //responseType: 'stream'
            responseType: 'json'
        })
            .then(function (response) {
                console.log(response);
                setItemsArray(response.data[0]);

                //ResponseStatus
                console.log(response.data[1]);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // always executed

            });
    }

    return (
        <div style={{ backgroundColor: '#333' }}>
            <header className="App-header backgroundColor" style={{ backgroundImage: `url("MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>
                <div class="scooped-corners demo-element backgroundColor mt-5 mb-5" id="demo">
                    <div class="container">
                        <h1 className='cursive3'>
                            {itemsArray.name}
                        </h1>
                        <img src='steak.jpg' className="App-logo" alt="logo" />

                        <h3 className='cursivesm m-4' >{itemsArray.country}</h3>
                        <p className='cursiveSm' >{itemsArray.description}</p>
                        <Link className='btn btn-primary shadow m-5 py-3 px-5' to={'/'+itemsArray.id+"/menus"}>
                            Menus
                        </Link>
                        <Link className='btn btn-primary shadow m-5 py-3 px-5' to="/checkin">
                            Check In
                        </Link>
          
                        <p onClick={() => { handleShow() }}>
                            ABOUT / HOURS / LOCATION / RESERVATIONS
                        </p>
                    
                    </div>

                </div>
            </header>

            <Offcanvas
                show={show}
                placement="end"
                scroll='false'
                backdrop='true'
                onHide={handleClose}>

                <Offcanvas.Header closeButton>

                    <Offcanvas.Title>
                        name
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>description</p>

                    {/*CAMERA SOCIAL SHARE STYLE CAnonical */}
                    <a id='ar-link' href="mask.usdz#canonicalWebPageURL=https://www.vizcache.com" rel="ar">
                        <img src='salad.jpg' width='100%' />
                    </a>

                    <hr />


                    Map
                    Hours
                    About
                    Entertainement
                    Reservations
                    <hr />
                    <Button>Add to Order</Button>
                    <hr />
                    Loading UI | Some text as placeholder.In real life you can have the elements you
                    have chosen.Like, text, images, lists, etc.
                    < Placeholder visibility={placeShow} as={Offcanvas.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder visibility={placeShow} as={Offcanvas.Text} animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                        <Placeholder xs={6} /> <Placeholder xs={8} />
                    </Placeholder>
                    <hr />

                </Offcanvas.Body>
                <BrowserView>
                    <Alert variant='secondary' className='m-0' onClose={() => setAlertShow(false)} dismissible>
                        <img src='ar.png' width='45px' />

                        Offcanvas Detail for ID:{id}
                        <h6>This is rendered only in browser</h6>
                        <hr />

                    </Alert>

                </BrowserView>
                <AndroidView>
                    <Alert variant='secondary' className='m-0'>
                        <h6>This is rendered only on Android</h6>
                    </Alert>
                </AndroidView>
                <IOSView>
                    <Alert variant='secondary' className='m-0'>
                        <h6>This is rendered only on IOS</h6>
                    </Alert>
                </IOSView>
            </Offcanvas>

        </div>
    )
}

export default Restaurant;

/*
                        {itemsArray.map((data, key) => {
                        return (
                            <Card key={key} className="mr-auto">
                                <Card.Body as={Link} to={`/` + data.restaurant_id + `/menu/` + data.slug} >
                                    <span className='text-dark m-2' title={data.description}>
                                        {data.name}
                                        {data.country}
                                    </span>
                                </Card.Body>
                            </Card>

                        )
                    })}
                    */