import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
//import logo from './logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Offcanvas from 'react-bootstrap/Offcanvas';


//import Menu from './components/Menu';
//import Item from './components/Item';
import AppMenu from '../AppMenu';


const axios = require('axios').default;

function Valet(props) {
    const [id, setID] = useState('');
    const [show, setShow] = useState(false);


    //GET
    const [itemsArray, setItemsArray] = useState([]);
    //POST


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    ////
    function getUser() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: 'http://bit.ly/2mTM3nY',
            responseType: 'stream'
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    function postUser() {
        // Send a POST request
        axios({
            method: 'post',
            url: '/user/12345',
            data: {
                firstName: 'Fred',
                lastName: 'Flintstone'
            }
        }).then(function (response) {
            console.log(response);
        })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    };

    return (

        <div>
            <AppMenu data={props.data} />
            <div className='p-0 m-0'>
                <h1 className='pt-4 p- cursive backgroundColor text-light' style={{ marginBottom: 0 }}>Valet</h1>
            </div>
            <h2 onClick={() => { handleShow(); setID(1); }}>New Order</h2>
            <Container>
  
            </Container>
            <h2>Tables</h2>

            <h2>Active Orders</h2>

            <h2>Closed Orders</h2>
            -TableId
            -ServerId
            -OrderId
            <Offcanvas
                show={show}
                placement="end"
                scroll='false'
                backdrop='true'
                onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>


                        ID:{id}

                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <Form>
                    ORDER ID:
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Server</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Please Select a Server Name</option>
                            <option value="1">Jane</option>
                            <option value="2">John</option>
                            <option value="3">Rasputin</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Table</Form.Label>
                        <Form.Select aria-label="Default select example">
                            <option>Please Select a table Id</option>
                            <option value="1">Table 1</option>
                            <option value="2">Table 2</option>
                            <option value="3">Table 3</option>
                        </Form.Select>
                        <Form.Text className="text-muted">
                            Interior, Patio, Bar...
                        </Form.Text>
                    </Form.Group>

                    Item X Quantity X Chef Notes

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" />
                        <Form.Text className="text-muted">
                            We'll never share your email with anyone else.
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Check me out" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Confirm Order
                    </Button>
                </Form>





                    <Button>Save</Button>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default Valet;
