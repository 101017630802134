import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
//import logo from './logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//https://github.com/duskload/react-device-detect/blob/HEAD/docs/views.md
import { browserName, browserVersion } from "react-device-detect";
import { BrowserView, TabletView, MobileView, AndroidView, IOSView, isBrowser, isMobile } from 'react-device-detect';

import Alert from 'react-bootstrap/Alert';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Placeholder from 'react-bootstrap/Placeholder';


import AppMenu from '../AppMenu';

//import Menu from './components/Menu';
//import Item from './components/Item';

const axios = require('axios').default;

function Menu(props) {
    const [show, setShow] = useState(false);
    const [alertShow, setAlertShow] = useState(true);
    const [placeShow, setPlaceShow] = useState(false);
    const [itemsArray, setItemsArray] = useState([]);
    const [detailArray, setDetailArray] = useState([]);
    const [id, setID] = useState('');
    const [ar, setAR] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const setDetail = (id) => {
        setDetailArray(itemsArray[id]);
        console.log('detailArray', detailArray);
    };



    useEffect(() => {
        checkAR();
        //setARInteract(id);

        getData();
        console.log('itemsArray', itemsArray);


    }, [ar])

    /*
    useEffect(()=>{

    },[detailArray])
    */

    ///
    function getData() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: props.data.apiUrl + props.data.restaurantId + '/items',
            //responseType: 'stream'
            responseType: 'json'
        })
            .then(function (response) {
                console.log(response);
                setItemsArray(response.data[0]);

                //ResponseStatus
                console.log(response.data[1]);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function (response) {
                // always executed

            });
    }


    function postData() {
        // Send a POST request
        axios({
            method: 'post',
            url: '/user/12345',
            data: {
                firstName: 'Fred',
                lastName: 'Flintstone'
            }
        }).then(function (response) {
            console.log(response);
        })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    };

    function checkAR() {
        const a = document.createElement("a");
        if (a.relList.supports("ar")) {
            // AR is available.
            console.log('AR is available!')
            setAR('Device Supports AR!');
        } else {
            console.log('AR is NOT available.')
            setAR('Sorry! This device does not support Augmented Reality. Try a different mobile phone or browser.');
        }
    }

    function setARInteract(CTAid, event) {


        //https://developer.apple.com/documentation/arkit/adding_an_apple_pay_button_or_a_custom_action_in_ar_quick_look
        const linkElement = document.getElementById("ar-link");
        linkElement.addEventListener("message", function (event) {
            alert('AR TAPPED')
        }, false);

        /*
        const linkElement = document.getElementById("ar-link");
        linkElement.addEventListener("message", function (event) {
            if (event.data == "_apple_ar_quicklook_button_tapped") {
                // handle the user tap.   
            }
        }, false);
        */
    }

    return (

        <div style={{ height: `calc(100vh - 40px)`, backgroundImage: `url("MenuCoverTextures/0090b-512x512.jpeg")` }}>
            <AppMenu data={props.data} />
            <div className='p-0 m-0'>
                <h1 className='pt-4 p- cursive backgroundColor text-light' style={{ marginBottom: 0 }}>All</h1>
            </div>
            <BrowserView>
                <Alert variant='secondary' className='m-0'>
                    <h6>This is rendered only in browser</h6>
                    <div>
                        RestaurantId: {props.data.restaurantId}<br></br>
                        MenuId: {props.data.menuId}<br></br>
                        Locale: {props.data.menuLocale}<br></br>
                    </div>
                </Alert>

            </BrowserView>
            <TabletView>
                <Alert variant='secondary' className='m-0'>
                    <h6>This is rendered only on tablet</h6>
                </Alert>
            </TabletView>
            <MobileView>
                <Alert variant='secondary' className='m-0'>
                    <h6>This is rendered only on mobile</h6>
                </Alert>
            </MobileView>
            <AndroidView>
                <Alert variant='secondary' className='m-0'>
                    <h6>This is rendered only on Android</h6>
                </Alert>
            </AndroidView>
            <IOSView>
                <Alert variant='secondary' className='m-0'>
                    <h6>This is rendered only on IOS</h6>
                </Alert>
            </IOSView>


            <ListGroup as="ol" className="m-0">
                {itemsArray.map((data, key) => {
                    return (
                        <ListGroup.Item
                            key={key}
                            as="li"
                            style={{ backgroundColor: '#ffffff99' }}
                            className="p-0 d-flex justify-content-between align-items-start"
                            onClick={() => { handleShow(); setID(data.id); setDetail(key); }}
                        >
                            <img src={'/'+data.thumbnailLink} width='125px' />
                            <div className="ms-2 me-auto m-auto" >
                                <div className="p-0 d-flex justify-content-between align-items-start">
                                    <div className="fw-bold">{data.name}</div>

                                </div>
                                <div>
                                    {data.description}
                                </div>
                            </div>
                            <div className="p-0 d-flex justify-content-between align-items-start">
                                <img src='/ar.png' width='50px' />



                            </div>
                            <div className="p-0 d-flex justify-content-between align-items-middle">
                                <Badge variant="primary" className='m-4' pill>
                                    $ {data.price}
                                </Badge>
                            </div>

                        </ListGroup.Item>
                    )
                })}
            </ListGroup>



            <Offcanvas
                show={show}
                placement="end"
                scroll='false'
                backdrop='true'
                onHide={handleClose}>

                <Offcanvas.Header closeButton>

                    <Offcanvas.Title>
                        {detailArray.name}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <p>{detailArray.description}</p>

                    {/*CAMERA SOCIAL SHARE STYLE CAnonical */}
                    <a id='ar-link' href="mask.usdz#canonicalWebPageURL=https://www.vizcache.com" rel="ar">
                        <img src='salad.jpg' width='100%' />
                    </a>

                    <hr />
                    <h5>{detailArray.category}</h5>
                    <h6>{detailArray.sub_category}</h6>

                    <Badge>{detailArray.price}</Badge>
                    <input>

                    </input>
                    Chef Notes
                    <hr />
                    <Button>Add to Order</Button>
                    <hr />
                    Loading UI | Some text as placeholder.In real life you can have the elements you
                    have chosen.Like, text, images, lists, etc.
                    < Placeholder visibility={placeShow} as={Offcanvas.Title} animation="glow">
                        <Placeholder xs={6} />
                    </Placeholder>
                    <Placeholder visibility={placeShow} as={Offcanvas.Text} animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                        <Placeholder xs={6} /> <Placeholder xs={8} />
                    </Placeholder>
                    <hr />

                </Offcanvas.Body>
                <BrowserView>
                    <Alert variant='secondary' className='m-0' onClose={() => setAlertShow(false)} dismissible>
                        <img src='ar.png' width='45px' />

                        Offcanvas Detail for ID:{id}
                        <h6>This is rendered only in browser</h6>
                        <hr />
                        {browserName}, {browserVersion} |
                        {ar}
                    </Alert>

                </BrowserView>
                <AndroidView>
                    <Alert variant='secondary' className='m-0'>
                        <h6>This is rendered only on Android</h6>
                    </Alert>
                </AndroidView>
                <IOSView>
                    <Alert variant='secondary' className='m-0'>
                        <h6>This is rendered only on IOS</h6>
                    </Alert>
                </IOSView>
            </Offcanvas>
        </div >
    );
}

export default Menu;


//                        {itemsArray[id]['name'] ?(itemsArray[id]['name']):('Nothing')}

/*
TEST MOCK API LIST
    const itemData = [
        { id: '1', name: 'Salad', image: 'salad.jpg', desc: 'An amazing salad..', price: '14', allergy: 'fish' },
        { id: '2', name: 'Burger', image: 'salad.jpg', desc: 'An amazing burger..', price: '12', allergy: 'nuts' },
        { id: '3', name: 'Mount Nacho', image: 'salad.jpg', desc: 'An amazing nacho plate..', price: '21', allergy: 'nuts' }
    ]

           <ListGroup as="ol" className="m-0">
                {itemData.map((data, key) => {
                    return (
                        <ListGroup.Item
                            key={key}
                            as="li"
                            style={{ backgroundColor: '#ffffff99' }}
                            className="p-0 d-flex justify-content-between align-items-start"
                            onClick={() => { handleShow(); setID(data.id); }}
                        >
                            <img src={data.image} width='125px' />
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{data.name}</div>
                                {data.desc}
                            </div>
                            <img src='ar.png' width='50px' />

                            <Badge variant="primary" className='m-4' pill>
                                $ {data.price}
                            </Badge>

                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
            */


/*
                                //CTA STYLE
                                <a id='ar-link' href="mask.usdz#checkoutTitle=Biplane%20Toy&checkoutSubtitle=Rustic%20finish%20with%20rotating%20propeller&price=$15&callToAction=Add%20to%20cart" rel="ar">
                                <img src='salad.jpg' width='100%' />
                                CTA
                            </a>

                            */
