import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
//import logo from './logo.svg';
import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Card from 'react-bootstrap/Card';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



//import Menu from './components/Menu';
//import Item from './components/Item';
import AppMenu from '../AppMenu';

//import { Sanctum, useSanctum } from "react-sanctum";


const axios = require('axios').default;

function Dashboard(props) {
    const [id, setID] = useState('');
    const [show, setShow] = useState(false);


    //GET
    const [itemsArray, setItemsArray] = useState([]);
    //POST
    const [itemName, setItemName] = useState('');
    const [itemPrice, setItemPrice] = useState('');
    const [itemThumb, setItemThumb] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    ///
    useEffect(() => {

        getData();

    }, [])

    function getData() {
        // Optionally the request above could also be done as
        axios({
            method: 'get',
            url: props.data.apiUrl + props.data.restaurantId + '/items',
            //responseType: 'stream'
            responseType: 'json'
        })
            .then(function (response) {
                console.log(response);
                setItemsArray(response.data[0]);
                //ResponseStatus
                console.log(response.data[1]);
            })
            .catch(function (error) {
                console.log(error);
            })
            .then(function () {
                // always executed
            });
    }

    function addItem(e) {
        e.preventDefault();
        // Send a POST request
        var bodyFormData = new FormData();
        bodyFormData.append('_method', 'post');
        bodyFormData.append('_csrf', '');
        bodyFormData.append('restaurant_id', props.data.restaurantId);
        bodyFormData.append('category', 'Vegan');
        bodyFormData.append('sub_category', 'Burgers');

        //
        //bodyFormData.append('name', itemName);
        bodyFormData.append('name', 'A Cool Name');
        bodyFormData.append('description', 'A Good Description.');
        //bodyFormData.append('price', itemPrice);
        bodyFormData.append('price', '15.99');
        bodyFormData.append('priceUnit', 'piece');
        bodyFormData.append('allergies', '[1,2,3]');
        bodyFormData.append('ageLimit', '0');
        bodyFormData.append('tags', '[1,2,3]');
        bodyFormData.append('list', '1');
        bodyFormData.append('status', 'availiable');
        //
        bodyFormData.append('thumbnailLink', '/image.png');
        //bodyFormData.append('image', imageFile);
        bodyFormData.append('videoLink', '/image.mp4');
        bodyFormData.append('isAr', 1);
        bodyFormData.append('arLink', '/image.usdz');


        axios({
            method: "post",
            url: props.data.apiUrl + props.data.restaurantId + '/items',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then(function (response) {
                //handle success
                console.log(response);
            })
            .catch(function (response) {
                //handle error
                console.log(response);
            })
            .then(function () {
                // always executed
            });
    };
    /*
    const { authenticated, user, signIn } = useSanctum();
    console.log('authenticated',authenticated);
    console.log('user',user);
    */
    const authenticated = true;

    return (

        <div style={{ backgroundImage: `url("/MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>
            <AppMenu data={props.data} />
            <div className='p-0 m-0'>
                <h1 className='pt-4 p- cursive backgroundColor text-light' style={{ marginBottom: 0 }}>Dashboard</h1>
            </div>

            <Container>

                <h2 className='text-light'>Staff Dashboards</h2>
                {authenticated === true ? (

                    <Row className="show-grid" >

                        <Col sm={6} md={3} >
                            <Card className="mr-auto p-5 mb-5" >
                                <Card.Body as={Link} to={`/1/manager`} >
                                    <Card.Title>Managers</Card.Title>

                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} md={3} >
                            <Card className="mr-auto p-5 mb-5">
                                <Card.Body as={Link} to={`/1/server`} >
                                    <Card.Title>Servers</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} md={3} >
                            <Card className="mr-auto p-5 mb-5">
                                <Card.Body as={Link} to={`/1/bartender`} >
                                    <Card.Title>Bartenders</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} md={3} >
                            <Card className="mr-auto p-5 mb-5">
                                <Card.Body as={Link} to={`/1/cooks`} >
                                    <Card.Title>Kitchen</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} md={3} >
                            <Card className="mr-auto p-5 mb-5">
                                <Card.Body as={Link} to={`/1/reception`} >
                                    <Card.Title>Reception</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} md={3} >
                            <Card className="mr-auto p-5 mb-5">
                                <Card.Body as={Link} to={`/1/valet`} >
                                    <Card.Title>Valet</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} md={3} >
                            <Card className="mr-auto p-5 mb-5">
                                <Card.Body as={Link} to={`/1/promoter`} >
                                    <Card.Title>Promoter</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col sm={6} md={3} >
                            <Card className="mr-auto p-5 mb-5">
                                <Card.Body as={Link} to={`/1/courier`} >
                                    <Card.Title>Courier</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                ) : (
                    <div>
                        Unauth
                    </div>
                )
                }

                <h2 className='text-light'>Restaurant Lists</h2>

                <Row className="show-grid" >

                    <Col sm={6} md={4} >
                        <Card className="mr-auto p-5 mb-5" >
                            <Card.Body as={Link} to={`/1/tables`} >
                                <Card.Title>Tables</Card.Title>

                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} >
                        <Card className="mr-auto p-5 mb-5">
                            <Card.Body as={Link} to={`/1/staff`} >
                                <Card.Title>Staff</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={6} md={4} >
                        <Card className="mr-auto p-5 mb-5">
                            <Card.Body as={Link} to={`/1/orders`} >
                                <Card.Title>Orders</Card.Title>
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>

            </Container>


            <Offcanvas
                show={show}
                placement="end"
                scroll='false'
                backdrop='true'
                onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>


                        ID:{id}

                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <img src='ar.png' width='100px' />
                    ItemEditForm





                    <Button>Save</Button>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default Dashboard;
