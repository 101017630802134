import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { LinkContainer } from 'react-router-bootstrap'
import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

//https://github.com/duskload/react-device-detect/blob/HEAD/docs/views.md
import { browserName, browserVersion } from "react-device-detect";
import { BrowserView, TabletView, MobileView, AndroidView, IOSView, isBrowser, isMobile } from 'react-device-detect';

import Alert from 'react-bootstrap/Alert';

import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import ListGroup from 'react-bootstrap/ListGroup';
import Form from 'react-bootstrap/Form';

import Menus from './components/Menus';
import Menu from './components/Menu';
import MenuCategory from './components/MenuCategory';
import Server from './components/Server';
import Manager from './components/Manager';
import Bartender from './components/Bartender';
import Promoter from './components/Promoter';
import Courier from './components/Courier';

import Login from './components/Login';
import Logout from './components/Logout';

import Register from './components/Register';
import Me from './components/Me';



import Cooks from './components/Cooks';
import Reception from './components/Reception';
import Valet from './components/Valet';
import Orders from './components/Orders';
import MenuManager from './components/MenuManager';
import Checkin from './components/Checkin';
import Item from './components/Item';
import Restaurant from './components/Restaurant';
import Dashboard from './components/Dashboard';
import Tables from './components/Tables';
import Staff from './components/Staff';




//import Scan from './components/Scan';
import QrReader from 'react-qr-reader'
//import { Sanctum, useSanctum } from "react-sanctum";

import axios from 'axios';
/*
const http = axios.create({
  baseURL: API_URL,
  withCredentials: true
})
*/

//const axios = require('axios').default;
//axios.defaults.baseURL = "http://localhost:8000/";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.withCredentials = true;
/*
axios.interceptors.request.use(
  function (config){
    const token = localStorage.getItem('auth_token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;

  }
);
*/


const sanctumConfig = {
  apiUrl: "https://www.api.maincourse.io",
  csrfCookieRoute: "sanctum/csrf-cookie",
  signInRoute: "api/v1/login",
  signOutRoute: "api/v1/logout",
  userObjectRoute: "api/v1/user",
};


function App() {
  const [QRresult, setQRResult] = useState([]);
  const [restaurantId, setRestaurantId] = useState('');
  const [menuId, setMenuId] = useState('');
  const [menuLocale, setMenuLocale] = useState('');


  const data = {
    restaurantId: restaurantId,
    menuId: menuId,
    menuLocale: menuLocale,
    apiUrl: 'https://www.api.maincourse.io/api/v1/'
  }

  useEffect(() => {
    setRestaurantId('1');
    setMenuId('1');
    setMenuLocale('EN');
    //getData();

  }, [restaurantId, menuId])

  ///
  function getData() {
    // Optionally the request above could also be done as
    axios({
      method: 'get',
      url: data.apiUrl + 'restaurants/',
      //responseType: 'stream'
      responseType: 'json'
    })
      .then(function (response) {
        console.log(response);
        //setItemsArray(response.data[0]);

        //ResponseStatus
        console.log(response.data[1]);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function (response) {
        // always executed

      });
  }

  function postData() {
    // Send a POST request
    axios({
      method: 'post',
      url: '/user/12345',
      data: {
        firstName: 'Fred',
        lastName: 'Flintstone'
      }
    }).then(function (response) {
      console.log(response);
    })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  function parseURL(data) {
    /*
    let payload = data.slice(21);
    var mySubString = payload.split('/');
    setId(mySubString[1]);
    setContract(mySubString[0]);
    console.log(mySubString);
    return mySubString
    */
  }

  function handleScan(data) {
    if (data) {
      setQRResult(
        data
      )
      //Parse QRCode URL
      //Call Api to Display button.
      //let id = data.slice(64);
      //let contract = data.slice(21,63);
      //let urlParts = parseURL(data);


      //loadApi(urlParts[0], urlParts[1]);

      //OR REDIRECT
      //window.location.href = data;
      //history.push("/" + data.slice(21));

    }
  }
  function handleError(err) {
    console.error(err)
  }

  return (
    //<Sanctum config={sanctumConfig}>

      <Router>
        <div className="App">
          <Switch>
            <Route key="1" exact path="/"> <Home data={data}></Home></Route>
            <Route key="55" exact path="/login"> <Login data={data}></Login></Route>
            <Route key="58" exact path="/logout"> <Logout data={data}></Logout></Route>

            <Route key="56" exact path="/register"> <Register data={data}></Register></Route>
            <Route key="57" exact path="/me"> <Me data={data}></Me></Route>



            <Route key="14" exact path="/:restaurant_id/tables"> <Tables data={data} /></Route>
            <Route key="15" exact path="/:restaurant_id/staff"> <Staff data={data} /></Route>
            <Route key="5" exact path="/:restaurant_id/orders"> <Orders data={data} /></Route>



            <Route key="13" path="/:restaurant_id/menus"> <Menus data={data} /></Route>
            <Route key="2" exact path="/:id/menu"><Menu data={data} /></Route>
            <Route key="3" exact path="/:id/server"> <Server data={data} /></Route>
            <Route key="4" exact path="/:id/cooks"> <Cooks data={data} /></Route>

            <Route key="41" exact path="/:id/manager"> <Manager data={data} /></Route>
            <Route key="42" exact path="/:id/bartender"> <Bartender data={data} /></Route>
            <Route key="43" exact path="/:id/reception"> <Reception data={data} /></Route>
            <Route key="44" exact path="/:id/valet"> <Valet data={data} /></Route>
            <Route key="45" exact path="/:id/promoter"> <Promoter data={data} /></Route>
            <Route key="46" exact path="/:id/courier"> <Courier data={data} /></Route>


            <Route key="6" exact path="/MenuManager"> <MenuManager data={data} /></Route>
            <Route key="7" exact path="/item"> <Item data={data} /></Route>
            <Route key="8" exact path="/checkin"> <Checkin data={data} /></Route>
            <Route key="9" exact path="/about"> <About data={data} /></Route>
            <Route key="9" exact path="/reservations"> <Reservations data={data} /></Route>
            <Route key="10" exact path="/scan"> <Scan data={data} /></Route>
            <Route key="11" path="/:restaurant_id/menu/:category"> <MenuCategory data={data} /></Route>
            <Route key="12" path="/:restaurant_id/"> <Dashboard data={data} /></Route>



          </Switch>

          <div style={{ position: 'fixed', bottom: '0px', width: '100vw', height: '40px', backgroundColor: '#333' }}>
            <span className='m-3' style={{ color: '#bbb' }}>Premium augmented menus deliciously served by <strong><Link to='/'>maincourse.io</Link></strong></span>
          </div>
        </div>


      </Router>

    //</Sanctum>

  );
}

export default App;

function List(props) {

  const [itemsArray, setItemsArray] = useState([]);
  useEffect(() => {

    getData();

  }, [])

  function getData() {
    // Optionally the request above could also be done as
    axios({
      method: 'get',
      url: props.data.apiUrl + 'restaurants/',
      //responseType: 'stream'
      responseType: 'json'
    })
      .then(function (response) {
        console.log(response);
        setItemsArray(response.data[0]);

        //ResponseStatus
        console.log(response.data[1]);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function (response) {
        // always executed

      });
  }

  return (
    <div>
      {itemsArray.map((data, key) => {
        return (
          <Card key={key} className="mr-auto">
            <Card.Body as={Link} to={`/` + data.id} >
              <span className='text-dark m-2' title={data.description}>
                {data.name}
                {data.country}

              </span>
            </Card.Body>
          </Card>

        )
      })}
    </div>

  )
}

function Scan(props) {
  const [QRresult, setQRResult] = useState([]);

  function handleScan(data) {
    if (data) {
      setQRResult(
        data
      )
      //Parse QRCode URL
      //Call Api to Display button.
      //let id = data.slice(64);
      //let contract = data.slice(21,63);
      //let urlParts = parseURL(data);


      //loadApi(urlParts[0], urlParts[1]);

      //OR REDIRECT
      //window.location.href = data;
      //history.push("/" + data.slice(21));

    }
  }
  function handleError(err) {
    console.error(err)
  }
  function getUser() {
    // Optionally the request above could also be done as
    axios({
      method: 'get',
      url: 'http://bit.ly/2mTM3nY',
      responseType: 'stream'
    })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  }

  function postUser() {
    // Send a POST request
    axios({
      method: 'post',
      url: '/user/12345',
      data: {
        firstName: 'Fred',
        lastName: 'Flintstone'
      }
    }).then(function (response) {
      console.log(response);
    })
      .catch(function (error) {
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  return (

    <div style={{ height: `calc(100vh - 40px)`, backgroundImage: `url("MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>

      <div style={{ backgroundColor: '#333' }}>
        <header className="App-header backgroundColor" style={{ backgroundImage: `url("MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>
          <div class="scooped-corners demo-element backgroundColor mt-5 mb-5" id="demo">
            <div class="container">
              <h1 className='cursive3'>
                Main Course
              </h1>
              <hr className='style-seven' />
              <QrReader
                //resolution={1000}
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: '100%' }}>
              </QrReader>

              <p>{QRresult}</p>

              <h3 className='cursivesm m-4' >Premium Augmented Digital  Menus</h3>
              <Link className='btn btn-primary shadow m-5 py-3 px-5' to="/1">
                Restaurant
              </Link>

              <BrowserView>
                <Alert variant='secondary' className='m-0'>
                  <h6>This is rendered only in browser</h6>

                </Alert>

              </BrowserView>
              <TabletView>
                <Alert variant='secondary' className='m-0'>
                  <h6>This is rendered only on tablet</h6>
                </Alert>
              </TabletView>
              <MobileView>
                <Alert variant='secondary' className='m-0'>
                  <h6>This is rendered only on mobile</h6>
                </Alert>
              </MobileView>
              <AndroidView>
                <Alert variant='secondary' className='m-0'>
                  <h6>This is rendered only on Android</h6>
                </Alert>
              </AndroidView>
              <IOSView>
                <Alert variant='secondary' className='m-0'>
                  <h6>This is rendered only on IOS</h6>
                </Alert>
              </IOSView>
              <List data={props.data}></List>
            </div>
          </div>
        </header>

      </div>
    </div >
  );
}

function Home() {

  return (
    <div style={{ backgroundColor: '#333' }}>
      <header className="App-header backgroundColor" style={{ backgroundImage: `url("MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>
        <div class="scooped-corners demo-element backgroundColor mt-5 mb-5" id="demo">
          <div class="container">
            <h1 className='cursive3'>
              Main Course
            </h1>
            <h3 className='cursivesm m-4' >Premium Augmented Menus</h3>

            <Form >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label className='cursiveSm'>Email address</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Text className="text-muted">
                </Form.Text>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label className='cursiveSm'>Password</Form.Label>
                <Form.Control type="password" placeholder="Password" />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check type="checkbox" className='cursiveSm' label="Keep me logged in." />
              </Form.Group>
              <Button as={Link} to="/1" variant="primary" className='btn btn-primary shadow m-5 py-3 px-5' type="submit">
                Login
              </Button>
            </Form>

            <p className='cursiveSm' >An complete and elegant fine dining experience for restaurants and clients.</p>


          </div>
        </div>
      </header>

    </div>
  )
}

function About() {

  return (
    <div style={{ backgroundColor: '#333' }}>
      <header className="App-header backgroundColor" style={{ backgroundImage: `url("MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>
        <div class="scooped-corners demo-element backgroundColor" id="demo">
          <div class="container">
            <h1 className='cursive3'>
              About Us
            </h1>
            <img src='steak.jpg' className="App-logo" alt="logo" />

            <h3 className='cursivesm m-4' >Premium Augmented Menus</h3>
            <p className='cursiveSm' >An elegant and simple fine dining experience.</p>
            <h1 className='cursive3'>
              Hours
            </h1>
            <Link className='btn btn-primary shadow m-5 py-3 px-5' to="/menu">
              Menu
            </Link>
            <Link className='btn btn-primary shadow m-5 py-3 px-5' to="/checkin">
              Check In
            </Link>
          </div>
        </div>
      </header>

    </div>
  )
}

function Reservations() {

  return (
    <div style={{ backgroundColor: '#333' }}>
      <header className="App-header backgroundColor" style={{ backgroundImage: `url("MenuCoverTextures/11-seamless-leather-texture.jpeg")` }}>
        <div class="scooped-corners demo-element backgroundColor" id="demo">
          <div class="container">
            <h1 className='cursive3'>
              Reservations
            </h1>
            <img src='steak.jpg' className="App-logo" alt="logo" />

            <h3 className='cursivesm m-4'>Choose a table and time.</h3>
            <Form >
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Table for </Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Text className="text-muted">
                  Please let us know how many people you need.
                </Form.Text>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Time and Date</Form.Label>
                <Form.Control type="email" placeholder="Enter email" />
                <Form.Text className="text-muted">
                  Please let us know when you will be arriving
                </Form.Text>
              </Form.Group>
              <Button variant="primary" type="submit">
                Request Reservation
              </Button>
            </Form>

            <Link className='btn btn-primary shadow m-5 py-3 px-5' to="/menu">
              Menu
            </Link>
            <Link className='btn btn-primary shadow m-5 py-3 px-5' to="/checkin">
              Check In
            </Link>
          </div>
        </div>
      </header>

    </div>
  )
}


/*
      <QrReader
        //resolution={1000}
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}


      />
      */
